exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-docp-js": () => import("./../../../src/pages/about-docp.js" /* webpackChunkName: "component---src-pages-about-docp-js" */),
  "component---src-pages-about-dpph-js": () => import("./../../../src/pages/about-dpph.js" /* webpackChunkName: "component---src-pages-about-dpph-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-seaphages-js": () => import("./../../../src/pages/about-seaphages.js" /* webpackChunkName: "component---src-pages-about-seaphages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-account-settings-js": () => import("./../../../src/pages/internal/account_settings.js" /* webpackChunkName: "component---src-pages-internal-account-settings-js" */),
  "component---src-pages-internal-administration-js": () => import("./../../../src/pages/internal/administration.js" /* webpackChunkName: "component---src-pages-internal-administration-js" */),
  "component---src-pages-internal-dashboard-js": () => import("./../../../src/pages/internal/dashboard.js" /* webpackChunkName: "component---src-pages-internal-dashboard-js" */),
  "component---src-pages-internal-directory-js": () => import("./../../../src/pages/internal/directory.js" /* webpackChunkName: "component---src-pages-internal-directory-js" */),
  "component---src-pages-internal-profile-js": () => import("./../../../src/pages/internal/profile.js" /* webpackChunkName: "component---src-pages-internal-profile-js" */),
  "component---src-pages-internal-publisher-js": () => import("./../../../src/pages/internal/publisher.js" /* webpackChunkName: "component---src-pages-internal-publisher-js" */),
  "component---src-pages-internal-story-editor-js": () => import("./../../../src/pages/internal/story_editor.js" /* webpackChunkName: "component---src-pages-internal-story-editor-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-phages-js": () => import("./../../../src/pages/our_phages.js" /* webpackChunkName: "component---src-pages-our-phages-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sequenced-phages-js": () => import("./../../../src/pages/sequenced_phages.js" /* webpackChunkName: "component---src-pages-sequenced-phages-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */)
}

